import React from 'react';
import PropTypes from 'prop-types';
import s from './Themes.css';
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "isomorphic-style-loader/withStyles";
import ThemeThumb from "../../components/Common/ThemeThumb/ThemeThumb";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

class Themes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            lastPage: false, // На последней странице не было элементов. Дальше страницы не грузим
            themes: Array.isArray(this.props.themes) ? this.props.themes : []
        };
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight + 100;
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling.bind(this));
    }

    setLastPage() {
        this.setState({lastPage: true, loading: false});
    }

    getLoadQuery() {
        if (typeof this.props.getLoadQuery === 'function') {
            return this.props.getLoadQuery(this.state.page);
        }

        return `{themes(page:${this.state.page}){id,title,installs,description,workspace}}`;
    }

    async loadNextPage() {
        this.setState({ loading: true, page: this.state.page + 1 });

        let resp = await this.props.fetch('/graphql', {
            body: JSON.stringify({
                query: this.getLoadQuery(),
            }),
        });

        let themes = await resp.json();

        if (themes.data && themes.data.themes) {
            themes = themes.data.themes;

            if (themes.length > 0) {
                this.setState({loading: false, themes: this.state.themes.concat(themes)});
            }

            if (themes.length < 10) {
                this.setLastPage();
            }
        } else {
            this.setLastPage();
        }
    }

    trackScrolling() {
        if (!this.state.loading && !this.state.lastPage) {
            const wrappedElement = document.getElementById('theme-container');

            if (wrappedElement && this.isBottom(wrappedElement)) {
                this.loadNextPage();
            }
        }
    };

    render() {
        return (
            <div id={'theme-container'} className={s.root}>
                <Container maxWidth="md">
                    <Grid container>
                        {this.state.themes.map(item => (
                            <Grid item md={4}>
                                <ThemeThumb theme={item}/>
                            </Grid>
                        ))}
                    </Grid>

                    {this.state.loading && <LinearProgress/>}
                </Container>
            </div>
        );
    }
}

export default withStyles(s)(Themes);