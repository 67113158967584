export async function reScreenshot(id) {
    return await fetch( '/graphql', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: 'mutation screenShot($id:String!) { screenshotTheme(id:$id) { id } }',
            variables: {
                id: id
            }
        }),
    });
}