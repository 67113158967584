// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:Rubik,\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--color-secondary:#fa9300}._1avl7{text-align:center}", ""]);
// Exports
exports.locals = {
	"root": "_1avl7"
};
module.exports = exports;
