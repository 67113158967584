// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3rwsA{text-decoration:none;font-weight:400}.cOfv4 button{margin-left:3px}.cOfv4{margin:10px;border-radius:4px;background:#fff;overflow:hidden;-webkit-box-shadow:0 7px 18px 0 rgba(0,0,0,.14);box-shadow:0 7px 18px 0 rgba(0,0,0,.14);text-decoration:none}.cOfv4:hover{-webkit-box-shadow:0 2px 4px 0 rgba(25,26,27,.4);box-shadow:0 2px 4px 0 rgba(25,26,27,.4);-webkit-transition:.3s ease;-o-transition:.3s ease;transition:.3s ease;-webkit-transform:scale(1.3);-ms-transform:scale(1.3);transform:scale(1.3)}._1gGrw{color:#555;font-weight:400;height:22px;font-size:14px;overflow:hidden;white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"link": "_3rwsA",
	"card": "cOfv4",
	"title": "_1gGrw"
};
module.exports = exports;
