import React from 'react';
import Link from "../../Link";
import useStyles from "isomorphic-style-loader/useStyles";
import s from "./ThemeThumb.css";
import {Button} from "@material-ui/core";
import getUser, {isAdmin} from "../../../utils/getUser";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {reScreenshot} from "../../../data/fetch/reScreenshot";

const styles = {
    media: {
        height: 147
    },
    content: {
        margin: 10
    },
    installs: {
        textAlign: 'right'
    }
};

export default function ThemeThumb({ theme }) {
    useStyles(s);
    const user = getUser();
    let [ loadingReScreenshot, setLoadingReScreenshot ] = React.useState(false);
    const admin = isAdmin(user);

    function ThemeLink({children}) {
        return (
            <Link to={`/theme/${theme.id}`} className={s.link}>{children}</Link>
        )
    }

    async function screenshot() {
        setLoadingReScreenshot(true);
        await reScreenshot(theme.id);
        setLoadingReScreenshot(false);
    }

    return (
        <div className={s.card}>
            <ThemeLink>
                <div style={{
                    ...styles.media,
                    background: `url(/screenshots/400/${theme.id}-${theme.workspace}.jpg) 100% 100% no-repeat`,
                    backgroundSize: 'cover'
                }} />
            </ThemeLink>

            <div style={styles.content}>
                <ThemeLink>
                    <span className={s.title}>
                        {theme.title}
                    </span>
                </ThemeLink>

                <div style={styles.installs}>
                    {   admin && !loadingReScreenshot &&
                    <Button color="primary" size={'small'} onClick={screenshot}>
                        Re-screenshot
                    </Button>
                    }

                    { loadingReScreenshot && <CircularProgress size={20} />}

                    <Button color="secondary" size={'small'} onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open(`/demo/xdr${theme.id}`, '_blank') }}>
                        Demo
                    </Button>

                    <ThemeLink>
                        <Button color="primary" size={'small'}>
                            Details
                        </Button>
                    </ThemeLink>

                    {/*<GetAppIcon/> {theme.installs}*/}
                </div>
            </div>
        </div>
    );
}