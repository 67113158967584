import React from 'react';
import s from './Home.css';
import withStyles from "isomorphic-style-loader/withStyles";
import Container from "@material-ui/core/Container";
import HeadMessage from "../../components/Common/HeadMessage/HeadMessage";
import Button from "@material-ui/core/Button";

const link = 'https://chrome.google.com/webstore/detail/new-tab-studio-countdown/epngggilgnflanfabeldfpbgponcgpgl';
const firefoxLink = 'https://addons.mozilla.org/ru/firefox/addon/new-tab-studio-countdown/?src=site';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            lastPage: false, // На последней странице не было элементов. Дальше страницы не грузим
            themes: Array.isArray(this.props.themes) ? this.props.themes : []
        };
    }

    render() {
        return (
            <div className={s.root}>
                <Container>
                    <HeadMessage>
                        <h1>Rule your <span>new tab</span></h1>
                    </HeadMessage>

                    <iframe width="800" height="450" src="https://www.youtube.com/embed/fwwnMSSC4ec?autoplay=1&mute=1&loop=1" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>

                    <h5>Supercharge your new tab with draggable widgets, photos, GIFS and more</h5>

                    <Button href={link} color={'primary'} variant={'contained'}>Get New Tab Studio for free</Button>
                    <a href={link}><img src={'/chrome-web-store.png'} alt={'Chrome Web Store'} width={150} /></a>
                    <a href={firefoxLink}><img src={'/firefox-addons.png'} alt={'Firefox addon'} width={150} /></a>
                </Container>
            </div>
        );
    }
}

export default withStyles(s)(Home);