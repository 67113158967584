// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:Rubik,\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--color-secondary:#fa9300}.G05V7{margin:0 0 2rem;border-bottom:1px solid #ddd;padding-bottom:15px}._2dWd1{margin-top:0;font-size:24px;font-size:1.5rem}._1zW-M h1,._1zW-M h2,._1zW-M h3,._1zW-M h4,._1zW-M h5,._1zW-M h6{font-size:18px;font-size:1.125rem}._1zW-M pre{white-space:pre-wrap;font-size:14px;font-size:.875rem}._1zW-M img{max-width:100%}", ""]);
// Exports
exports.locals = {
	"item": "G05V7",
	"themeTitle": "_2dWd1",
	"newsDesc": "_1zW-M"
};
module.exports = exports;
