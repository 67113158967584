import React from 'react';
import Themes from './Themes';
import Layout from '../../components/Layout';
import HeadMessage from "../../components/Common/HeadMessage/HeadMessage";

async function action({ fetch }) {
  const resp = await fetch('/graphql', {
    body: JSON.stringify({
      query: '{themes{id,title,installs,description,workspace}}',
    }),
  });
  const { data } = await resp.json();
  if (!data || !data.themes) throw new Error('Failed to load themes.');

  return {
    title: 'All themes',
    chunks: ['themes'],
    component: (
      <Layout>
        <HeadMessage>
          <h1>User <span>themes</span></h1>
          <h5>Create new tab themes and share with friends</h5>
        </HeadMessage>

        <Themes key={'allthemes-list'} themes={data.themes} fetch={fetch} />
      </Layout>
    ),
  };
}

export default action;
